import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import Seo from "@/components/seo"

const CareerPage = ({ data, pageContext }) => {
  const { contentSections, metadata } = data.strapiCareer
  const global = data.strapiGlobal
  const navbar = data.strapiNavbar
  const footer = data.strapiFooter

  return (
    <>
      <Seo seo={metadata} global={global} />
      <Layout navbar={navbar} footer={footer} pageContext={{ ...pageContext }} slug="careers">
        <article className="career">
          <Sections sections={contentSections} />
        </article>
      </Layout>
    </>
  )
}

export default CareerPage

export const query = graphql`
  fragment GlobalDataCareers on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      metaKeywords
      shareImage {
        localFile {
          publicURL
        }
      }
    }
  }

  fragment NavbarData on StrapiNavbar {
    button {
      id
      newTab
      text
      type
      url
    }
    id
    links {
      url
      text
      newTab
      id
    }
  }

  fragment FooterData on StrapiFooter {
    id
    columns {
      id
      links {
        id
        newTab
        text
        url
      }
      title
    }
    id
    smallText
    email
    policies {
      id
      newTab
      text
      pdf {
        url
      }
    }
  }

  query DynamicCareerQuery($slug: String!) {
    strapiGlobal(locale: { eq: "en" }) {
      ...GlobalDataCareers
    }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
    strapiCareer(slug: { eq: $slug }) {
      id
      title
      blurb
      publishDate
      metadata {
        metaTitle
        metaDescription
        metaKeywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      contentSections
      cardBackground {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 800, height: 500)
          }
        }
      }
      
    }
  }
`